import React, {useContext, useEffect, useState} from 'react';
import {FlatList, Image, Linking, Platform, View, useWindowDimensions} from 'react-native';
import {
    Avatar,
    Button,
    Caption,
    Card,
    Chip,
    Dialog,
    Divider,
    Headline,
    IconButton,
    List,
    Modal,
    Portal,
    RadioButton,
    Subheading,
    Text,
    TextInput,
    useTheme,
} from 'react-native-paper';
import {StackScreenProps} from '@react-navigation/stack';
import {StackParamList} from 'linking';
import styled from 'styled-components/native';
import {BottomBar, PageTitle, PageContent, Row, getInitials, WarningTxt} from 'theme';
import {useNavigation} from '@react-navigation/native';
import TopBar from 'components/TopBar';
import DatePicker from 'components/DatePicker.web';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {Appointment, flags} from 'shared/appt.model';
import MapView from 'react-native-maps';
import {DateTime, Duration, SystemZone} from 'luxon';
import ICalendarLink from 'react-icalendar-link';
import {ApptContext, AuthContext} from 'shared/context';
import * as Location from 'expo-location';
import {LocationObject} from 'expo-location/src/Location.types';
import {Garage, GarageBay, GarageBayScheduledWork} from 'shared/garage.model';
import {APIService} from 'api';
import {ThenArg} from 'types';
import DropDownSelect, {SelectData} from 'components/DropDownSelect';
import RelatedApptsPicker from '/components/RelatedApptsPicker';
import ApptDetailCard from 'components/RelatedAppointmentCard';

type additionalNeed = {
    classification_id: number;
    memo: string;
};

async function getInitialDay(appt: Appointment | null, garage: Garage | null): Promise<DateTime | null> {
    const api = new APIService();
    if (appt && garage) {
        const due_date = appt.due_date != null ? DateTime.fromISO(appt.due_date) : null;
        const start_date =
            due_date && due_date < DateTime.now().plus({days: 3}) ? DateTime.now() : DateTime.now().plus({days: 2});
        const next_available = DateTime.fromISO(
            await api.getNextAvailableDay(
                Duration.fromISO(appt.duration as string),
                appt.vehicle?.vehicle_rating as any | 'combo',
                garage.id,
                start_date,
                appt.aepsatellite
            )
        );
        if (!next_available) return next_available;

        if (due_date)
            if (due_date < DateTime.now().plus({days: 7})) return next_available;
            else return due_date.minus({days: 7});
        else return DateTime.now().plus({days: 14});
    } else {
        return null;
    }
}

function isBatchSatellite(appts: Appointment[] | null): boolean {
    if (!appts) return true;

    for (let i = 0; i < appts.length; i++) if (appts[i].aepsatellite === 0) return false;

    return true;
}

function getSatelliteBayLatitude(selectedId: string, satelliteBays: GarageBay[], garage: Garage): number {
    if (selectedId !== '') {
        const selectedBayList = satelliteBays.filter((bay) => {
            return bay.id === parseInt(selectedId);
        });

        if (selectedBayList.length > 0 && selectedBayList[0].latitude !== null) return selectedBayList[0].latitude;
    }
    return garage.latitude;
}

function getSatelliteBayLongitude(selectedId: string, satelliteBays: GarageBay[], garage: Garage): number {
    if (selectedId !== '') {
        const selectedBayList = satelliteBays.filter((bay) => {
            return bay.id === parseInt(selectedId);
        });

        if (selectedBayList.length > 0 && selectedBayList[0].longitude !== null) return selectedBayList[0].longitude;
    }
    return garage.longitude;
}

export default function Scheduling({route}: StackScreenProps<StackParamList, 'Scheduling'>) {
    const {colors} = useTheme();
    const navigation = useNavigation();
    const {Marker} = MapView as any;

    const [step, setStep] = useState(0);
    const [rptDialogVisible, setRptDialogVisible] = useState(false);
    const [garage, setGarage] = useState<Garage | null>(null);
    const [reportCarMemo, setReportCarMemo] = useState('');
    const paramDate = route.params.date && DateTime.fromISO(route.params.date);
    const apptContext = useContext(ApptContext);
    const appt = apptContext.value;
    const [date, setDate] = useState<DateTime | null>(
        paramDate && paramDate.isValid ? paramDate : DateTime.now().plus({days: 14})
    );
    const [timeslots, setTimeslots] = useState<ThenArg<ReturnType<APIService['getAppointmentTimeslots']>>>([]);
    const [timeslot, setTimeslot] = useState<typeof timeslots[number] | undefined>();
    let desc = '';

    const [usrLocation, setUsrLocation] = useState<LocationObject | null>(null);
    const [locationText, setLocationText] = useState('Waiting..');
    const [loading, setLoading] = useState(false); // whether there is an outgoing schedule request we're waiting on
    const [dialogError, setDialogError] = useState('');
    const user = useContext(AuthContext).user;

    const [relatedAppts, setRelatedAppts] = useState<Appointment[]>([]);
    const [selectedRelatedAppts, setSelectedRelatedAppts] = useState<Appointment[]>([]);
    const {width} = useWindowDimensions();

    const [satelliteBays, setSatelliteBays] = useState<GarageBay[]>([]);
    const [selectedSatelliteBayId, setSelectedSatelliteBayId] = useState<string>('');

    const [expDuration, setExpDuration] = useState<string>('N/A');

    useEffect(() => {
        if (!appt || !appt.duration) {
            setExpDuration('N/A');
            return;
        }

        let total: Duration = Duration.fromISO(appt.duration);

        for (const relatedAppt of selectedRelatedAppts)
            if (relatedAppt.duration) total = total.plus(Duration.fromISO(relatedAppt.duration));

        setExpDuration(total.normalize().toHuman());
    }, [appt, selectedRelatedAppts]);

    const newAdditionalNeed: additionalNeed = {
        classification_id: 0,
        memo: '',
    };
    const [additionalNeeds, setAdditionalNeeds] = useState<additionalNeed[]>([newAdditionalNeed]);
    const [classificationSelectItem, setClassificationSelectItem] = useState<SelectData[]>([
        {
            label: 'No additional need',
            value: 0,
        },
    ]);

    if (appt?.work_type === 'PM') desc = 'Preventative Maintenance Needed';
    else if (appt?.work_type === 'CMPWO') desc = 'Recall is Required';
    else desc = 'Maintenance Required';

    function hasAdditionalNeed(additionalNeed: additionalNeed): boolean {
        return additionalNeed.classification_id !== 0;
    }

    // Request location permissions on first render
    useEffect(() => {
        (async () => {
            const {status} = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                setLocationText('Permission to access location was denied');
                return;
            }

            const location = await Location.getCurrentPositionAsync({});
            setUsrLocation(location);
        })();
    }, []);

    useEffect(() => {
        if (appt && step === 2) {
            const api = new APIService();
            const selectItems: SelectData[] = [
                {
                    label: 'No additional need',
                    value: 0,
                },
            ];
            api.getApptWorkClass()
                .then((classifications) => {
                    classifications.forEach((classification) => {
                        selectItems.push({
                            label: classification.description,
                            value: classification.id,
                        });
                    });
                    setClassificationSelectItem([...selectItems]);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [appt, step]);

    useEffect(() => {
        (async () => {
            if (appt) {
                const api = new APIService();
                const related = await api.getRelatedAppointment(appt.id);
                setRelatedAppts(related);
            }
        })();
    }, [appt]);

    // Recalculate driving distance when garage or location changes
    useEffect(() => {
        if (garage && usrLocation)
            getDrivingDistance(
                {lng: usrLocation.coords.longitude, lat: usrLocation.coords.latitude},
                {lng: garage.longitude, lat: garage.latitude}
            ).then((val) => {
                if (val)
                    setLocationText(
                        (val.distance
                            ? (Math.round((val.distance / 1609.34) * 10) / 10).toString() + ' miles away · '
                            : 'N/A') +
                            (val.duration ? (Math.round((val.duration / 60) * 10) / 10).toString() + ' min drive' : '')
                    );
            });
    }, [garage, usrLocation]);

    // Reset scheduling step & appt when parameter changes
    useEffect(() => {
        // do nothing if appointment matches parameter
        const id = parseInt(route.params.apptId as any);
        if (appt && appt.id === id) return;

        // fetch appointment data and set context
        const api = new APIService();
        api.getAppointment(route.params.apptId)
            .then((appt) => {
                apptContext.update(appt);
            })
            .catch((e) => {
                console.log(e);
                apptContext.update(null);
            });
        setStep(0);
        // Only need value of appt, not context
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.params?.apptId]);

    // Fetch garage from API when parameters change
    useEffect(() => {
        const id = route.params.garageId || appt?.garage_id;
        const api = new APIService();
        if (!id) {
            // Reset time & timeslots
            setTimeslot(undefined);
            setTimeslots([]);
            return;
        } else if (id === route.params.garageId) {
            api.getGarage(id).then((g) => {
                // Reset time & timeslots
                setTimeslot(undefined);
                setTimeslots([]);
                setGarage(g);
            });
            api.getSatelliteBays(id).then((bs) => {
                setSatelliteBays(bs);
            });
        } else {
            api.getGarageBay(id).then((g) => {
                setTimeslot(undefined);
                setTimeslots([]);
                setGarage(g.garage);
            });
            api.getSatelliteBaysFromBay(id).then((bs) => {
                setSatelliteBays(bs);
            });
        }
    }, [route.params.garageId, appt?.garage_id]); // Set garage when parameter changes

    // Fetch garage from API when parameters change
    useEffect(() => {
        if (route.params.satelliteBayId) setSelectedSatelliteBayId(route.params.satelliteBayId.toString());
        else setSelectedSatelliteBayId('');
    }, [route.params.satelliteBayId]); // Set satellitebayid on selection

    // When scheduling date, garage, or appt change on step 1, re-fetch timeslots
    useEffect(() => {
        if (!garage || !appt || step !== 1 || !date || !date.isValid) return;
        const api = new APIService();
        // It may be the case that our local timezone's date is different than UTC
        // So we first convert our datetime to local midnight, then UTC
        const startOfDay = date.toUTC().startOf('day');
        const cb = (slots: typeof timeslots) => {
            // Reset time & timeslots
            setTimeslot(undefined);
            setTimeslots(slots);
        };
        // if (selectedSatelliteBayId === '')
        appt.duration &&
            api
                .getAppointmentTimeslots(
                    Duration.fromISO(appt.duration),
                    appt.vehicle?.vehicle_rating as any | 'light',
                    garage.id,
                    startOfDay,
                    appt.aepsatellite
                )
                .then(cb)
                .catch((err) => {
                    // (Temporarily) handle error when requesting timeslots <=1 day in the future
                    if (err.message && err.message.includes('future')) cb([]);
                    else throw err;
                });
        // else
        //     appt.duration &&
        //         api
        //             .getSatelliteTimeslots(
        //                 Duration.fromISO(appt.duration),
        //                 appt.vehicle?.vehicle_rating as any | 'light',
        //                 garage.id,
        //                 parseInt(selectedSatelliteBayId),
        //                 startOfDay,
        //                 appt.aepsatellite
        //             )
        //             .then(cb)
        //             .catch((err) => {
        //                 // (Temporarily) handle error when requesting timeslots <=1 day in the future
        //                 if (err.message && err.message.includes('future')) cb([]);
        //                 else throw err;
        //             });
    }, [garage, date, appt, step, selectedSatelliteBayId]); // Update appointment timeslots when garage or date changes

    useEffect(() => {
        const paramDate = route.params.date && DateTime.fromISO(route.params.date);
        getInitialDay(appt, garage).then((initialDay) => {
            if (initialDay) setDate(paramDate && paramDate.isValid ? paramDate : initialDay);
            else setDate(null);
        });
    }, [appt, garage, route.params.date]); // update date when parameter changes

    return (
        <View style={{flex: 1, backgroundColor: '#fff'}}>
            <TopBar />

            {appt && step === 0 && (
                <>
                    <PageTitle>Confirm Vehicle</PageTitle>
                    <PageContent style={{overflow: 'hidden'}}>
                        <List.Item
                            title={appt.vehicle?.description ? appt.vehicle.description : 'Missing Vehicle Description'}
                            description={appt.vehicle?.alias ? appt.vehicle.alias : 'Missing Vehicle Alias'}
                            left={(props) => (
                                <MaterialCommunityIcons name='car-back' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        {appt.operator_id && appt.operator_id !== user.id && (
                            <>
                                <Divider />
                                <List.Item
                                    title={
                                        appt.operator
                                            ? appt.operator.first_name + ' ' + appt.operator.last_name
                                            : 'Vehicle has no Operator'
                                    }
                                    left={(props) => (
                                        <Avatar.Text
                                            size={28}
                                            label={getInitials(
                                                appt.operator &&
                                                    appt.operator.first_name + ' ' + appt.operator.last_name
                                            )}
                                        />
                                    )}
                                    style={{padding: 0}}
                                />
                            </>
                        )}
                        {appt.status && appt.status[0].status_code !== 'CONFIRMED' && (
                            <>
                                <div>
                                    <Subheading style={{fontWeight: 'bold'}}>No longer your unit?</Subheading>

                                    <Button
                                        mode='outlined'
                                        onPress={() => setRptDialogVisible(true)}
                                        uppercase={false}
                                        style={{borderColor: colors.accent}}
                                        theme={{colors: {primary: colors.accent}}}
                                    >
                                        Report
                                    </Button>
                                </div>
                                <Divider style={{height: 8}} />
                            </>
                        )}

                        <View style={{alignItems: 'flex-start'}}>
                            <ApptDetailCard appt={appt} />
                        </View>
                        {appt.duration && Duration.fromISO(appt.duration) > Duration.fromObject({hours: 8}) && (
                            <WarningTxt>This appointment will take more than 1 day.</WarningTxt>
                        )}
                        {/*{relatedAppts.length > 0 && (*/}
                        {/*    <RelatedApptsPicker*/}
                        {/*        appts={relatedAppts}*/}
                        {/*        selectedAppts={selectedRelatedAppts}*/}
                        {/*        selectAppts={(appts) => {*/}
                        {/*            setSelectedRelatedAppts(appts);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/*<View style={{alignItems: 'flex-start', maxWidth: '100%'}}>*/}
                        {/*    <FlatList*/}
                        {/*        horizontal={width > 600}*/}
                        {/*        style={{maxWidth: '100%'}}*/}
                        {/*        data={selectedRelatedAppts}*/}
                        {/*        renderItem={({item, index}) => (*/}
                        {/*            <>*/}
                        {/*                <ApptDetailCard key={index} appt={item} />*/}
                        {/*            </>*/}
                        {/*        )}*/}
                        {/*        ItemSeparatorComponent={() => (*/}
                        {/*            <View*/}
                        {/*                style={{*/}
                        {/*                    padding: 4,*/}
                        {/*                }}*/}
                        {/*            />*/}
                        {/*        )}*/}
                        {/*    />*/}
                        {/*</View>*/}
                    </PageContent>
                    <Portal>
                        <Dialog visible={rptDialogVisible} onDismiss={() => setRptDialogVisible(false)}>
                            <Dialog.Title>Report the vehicle no longer your unit</Dialog.Title>
                            <Dialog.Content>
                                <List.Item
                                    title={
                                        appt.vehicle?.description
                                            ? appt.vehicle.description
                                            : 'Missing Vehicle Description'
                                    }
                                    description={appt.vehicle?.alias ? appt.vehicle.alias : 'Missing Vehicle Alias'}
                                    left={(props) => (
                                        <MaterialCommunityIcons name='car-back' size={26} color={colors.primary} />
                                    )}
                                    style={{padding: 0}}
                                />
                                <Divider />
                                <Subheading style={{fontWeight: 'bold', marginTop: 16}}>Memo</Subheading>
                                <TextInput
                                    mode='outlined'
                                    multiline={true}
                                    placeholder={'Please provide the new operator’s name if known.'}
                                    value={reportCarMemo}
                                    onChangeText={(text) => setReportCarMemo(text)}
                                    style={{height: 150}}
                                />
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    onPress={() => {
                                        const api = new APIService();
                                        appt.vehicle_id &&
                                            appt.vehicle &&
                                            appt.vehicle.operator &&
                                            api
                                                .reportOwnership({
                                                    vehicle_id: appt.vehicle_id,
                                                    status: 'PENDING',
                                                    memo: 'Ownership Change - ' + reportCarMemo,
                                                    updated_by: user.id,
                                                    operator: appt?.vehicle?.operator,
                                                })
                                                .then(() => {
                                                    setRptDialogVisible(false);
                                                    navigation.navigate('Appointments', {status: 'unscheduled'});
                                                })
                                                .catch((err) => console.log(err));
                                    }}
                                >
                                    Submit
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>

                    <BottomBar style={{justifyContent: 'flex-end'}}>
                        <Button
                            icon='chevron-right'
                            mode='contained'
                            uppercase={false}
                            contentStyle={{flexDirection: 'row-reverse'}}
                            onPress={() => {
                                setStep(step + 1);
                                console.log(date);
                            }}
                        >
                            Next
                        </Button>
                    </BottomBar>
                </>
            )}
            {appt && step === 1 && (
                <>
                    <PageTitle>Choose Appointment</PageTitle>
                    <PageContent>
                        <List.Item
                            title={garage ? garage.description : 'N/A'}
                            titleStyle={{fontWeight: '600'}}
                            left={(props) => (
                                <MaterialIcons name='location-pin' size={26} color={colors.primary} uppercase={false} />
                            )}
                            right={(props) => (
                                <Button
                                    mode='text'
                                    onPress={() => {
                                        apptContext.update(appt);
                                        navigation.navigate('ModalScreens', {
                                            screen: 'ChangeGarage',
                                            params: {
                                                apptId: appt.id,
                                            },
                                            initial: false,
                                        });
                                    }}
                                >
                                    Change
                                </Button>
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <WarningTxt>Vehicles are expected 15 minutes prior to appointment time</WarningTxt>
                        <Divider />
                        {/*{satelliteBays.length > 0 &&*/}
                        {/*    appt.aepsatellite === 1 &&*/}
                        {/*    isBatchSatellite(selectedRelatedAppts) && (*/}
                        {/*        <WarningTxt>*/}
                        {/*            This appointment can be performed at the following satellite bays*/}
                        {/*        </WarningTxt>*/}
                        {/*    )}*/}
                        {/*{satelliteBays.length > 0 && appt.aepsatellite === 1 && isBatchSatellite(selectedRelatedAppts) && (*/}
                        {/*    <RadioButton.Group*/}
                        {/*        onValueChange={(newValue) => setSelectedSatelliteBayId(newValue)}*/}
                        {/*        value={selectedSatelliteBayId}*/}
                        {/*    >*/}
                        {/*        /!*<View style={{minHeight: 'auto'}}>*!/*/}
                        {/*        <View key={-1} style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>*/}
                        {/*            <RadioButton.Android value={''} theme={{colors: {accent: colors.primary}}} />*/}
                        {/*            <View style={{flex: 1}}>*/}
                        {/*                <Row alignment={'center'}>*/}
                        {/*                    <Subheading style={{fontWeight: '600'}}>*/}
                        {/*                        {'Remain at main garage: ' + garage?.description}*/}
                        {/*                    </Subheading>*/}
                        {/*                </Row>*/}
                        {/*                <Divider style={{marginTop: 16}} />*/}
                        {/*            </View>*/}
                        {/*        </View>*/}
                        {/*        {satelliteBays.map((bay, index) => (*/}
                        {/*            <View*/}
                        {/*                key={index.toString()}*/}
                        {/*                style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}*/}
                        {/*            >*/}
                        {/*                <RadioButton.Android*/}
                        {/*                    value={bay.id.toString()}*/}
                        {/*                    theme={{colors: {accent: colors.primary}}}*/}
                        {/*                />*/}
                        {/*                <View style={{flex: 1}}>*/}
                        {/*                    <Row alignment={'center'}>*/}
                        {/*                        <Subheading style={{fontWeight: '600'}}>*/}
                        {/*                            {bay.description ? bay.description : 'No Bay Description'}*/}
                        {/*                        </Subheading>*/}
                        {/*                    </Row>*/}
                        {/*                    <Divider style={{marginTop: 16}} />*/}
                        {/*                </View>*/}
                        {/*            </View>*/}
                        {/*        ))}*/}
                        {/*        /!*</View>*!/*/}
                        {/*    </RadioButton.Group>*/}
                        {/*)}*/}
                        <List.Item
                            title={(appt.work_type === 'PM' ? 'PM ' : '') + 'Due Date'}
                            description={
                                appt.due_date
                                    ? DateTime.fromISO(appt.due_date)
                                          .toUTC()
                                          .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
                                    : 'N/A'
                            }
                            left={(props) => (
                                <MaterialCommunityIcons name='calendar-clock' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        {Platform.OS === 'web' && garage && (
                            <Card style={{width: '100%'}}>
                                <Row alignment={'center'}>
                                    <IconButton
                                        icon='chevron-left'
                                        color={colors.primary}
                                        size={20}
                                        onPress={() => {
                                            if (date) setDate(date.minus({day: 1}));
                                        }}
                                        disabled={
                                            !date ||
                                            !date.isValid ||
                                            (appt.due_date !== null &&
                                                date.toUTC() > DateTime.fromISO(appt.due_date).toUTC()) ||
                                            date.toUTC() <= DateTime.now().toUTC()
                                        }
                                    />
                                    {/* datepicker's value format is HTML <input> default date format 'yyyy-mm-dd' */}
                                    {date && date.isValid ? (
                                        <DatePicker
                                            value={date.toUTC().toFormat('yyyy-MM-dd')}
                                            min={DateTime.now().toLocal().toFormat('yyyy-MM-dd')}
                                            max={(appt.due_date &&
                                            DateTime.fromISO(appt.due_date) < DateTime.now().toUTC().plus({day: 28})
                                                ? DateTime.fromISO(appt.due_date).toUTC()
                                                : DateTime.now().plus({day: 28})
                                            )
                                                .toLocal()
                                                .toFormat('yyyy-MM-dd')}
                                            onChange={(e) => {
                                                setDate(
                                                    DateTime.fromFormat(e.currentTarget.value, 'yyyy-MM-dd', {
                                                        zone: new SystemZone(),
                                                    })
                                                );
                                                // console.log(e.currentTarget.value);
                                            }}
                                        />
                                    ) : (
                                        <WarningTxt>
                                            No available scheduling options. Please select a different garage, or reach
                                            out to your garage directly.
                                        </WarningTxt>
                                    )}
                                    <IconButton
                                        icon='chevron-right'
                                        color={colors.primary}
                                        size={20}
                                        disabled={
                                            !date ||
                                            !date.isValid ||
                                            (appt.due_date !== null &&
                                                date.toUTC() > DateTime.fromISO(appt.due_date)) ||
                                            date.toUTC() >= DateTime.now().plus({day: 28}).toUTC()
                                        }
                                        onPress={() => {
                                            if (date) setDate(date.plus({day: 1}));
                                        }}
                                    />
                                </Row>
                                <Divider />
                                <TimeChipContainer style={{backgroundColor: colors.background}}>
                                    {timeslots
                                        .sort((slot1, slot2) => (slot1.start_time < slot2.start_time ? -1 : 1))
                                        .filter(
                                            (slot, index) =>
                                                index === 0 || slot.start_time > timeslots[index - 1].start_time
                                        )
                                        .map((slot, index) => (
                                            <Button
                                                key={index.toString()}
                                                mode={
                                                    slot.start_time === timeslot?.start_time ? 'contained' : 'outlined'
                                                }
                                                compact
                                                uppercase={false}
                                                theme={{colors: {primary: colors.primary}}}
                                                onPress={() => setTimeslot(slot)}
                                            >
                                                {DateTime.fromISO(slot.start_time).toLocal().toFormat('h:mma')}
                                            </Button>
                                        ))}
                                </TimeChipContainer>
                            </Card>
                        )}

                        <Subheading style={{fontWeight: 'bold', marginTop: 16}}>GARAGE LOCATION</Subheading>
                        {garage && (
                            <Card style={{width: '100%', height: 250}}>
                                <MapView
                                    initialRegion={{
                                        latitude: getSatelliteBayLatitude(
                                            selectedSatelliteBayId,
                                            satelliteBays,
                                            garage
                                        ),
                                        longitude: getSatelliteBayLongitude(
                                            selectedSatelliteBayId,
                                            satelliteBays,
                                            garage
                                        ),
                                        latitudeDelta: 0.0922,
                                        longitudeDelta: 0.0421,
                                    }}
                                    region={{
                                        latitude: getSatelliteBayLatitude(
                                            selectedSatelliteBayId,
                                            satelliteBays,
                                            garage
                                        ),
                                        longitude: getSatelliteBayLongitude(
                                            selectedSatelliteBayId,
                                            satelliteBays,
                                            garage
                                        ),
                                        latitudeDelta: 0.0922,
                                        longitudeDelta: 0.0421,
                                    }}
                                    style={{width: '100%', height: '70%'}}
                                    loadingEnabled={true}
                                    loadingIndicatorColor='#666666'
                                    loadingBackgroundColor='#eeeeee'
                                    showsCompass={false}
                                    scrollEnabled={false}
                                    toolbarEnabled={false}
                                    onPress={() => {
                                        Linking.openURL(
                                            'https://maps.google.com/?q=' + garage.latitude + ',' + garage.longitude
                                        );
                                    }}
                                >
                                    {/* Somehow the marker description doesn't show*/}
                                    {garage && (
                                        <Marker
                                            identifier={'12389dfd'}
                                            key={`test-${garage.id}`}
                                            coordinate={{
                                                latitude: getSatelliteBayLatitude(
                                                    selectedSatelliteBayId,
                                                    satelliteBays,
                                                    garage
                                                ),
                                                longitude: getSatelliteBayLongitude(
                                                    selectedSatelliteBayId,
                                                    satelliteBays,
                                                    garage
                                                ),
                                            }}
                                            title={garage.description}
                                        />
                                    )}
                                </MapView>
                                <Card.Content>
                                    <Subheading style={{fontWeight: 'bold'}}>{garage.address}</Subheading>
                                    <List.Item
                                        title={locationText}
                                        left={(props) => (
                                            <MaterialIcons name='near-me' size={20} color={colors.primary} />
                                        )}
                                        style={{padding: 0}}
                                        titleStyle={{color: colors.primary}}
                                    />
                                </Card.Content>
                            </Card>
                        )}
                    </PageContent>
                    <BottomBar style={{justifyContent: 'flex-end'}}>
                        <Button icon='chevron-left' mode='outlined' uppercase={false} onPress={() => setStep(step - 1)}>
                            Back
                        </Button>
                        <View style={{width: 5}} />
                        <Button
                            icon='chevron-right'
                            mode='contained'
                            uppercase={false}
                            contentStyle={{flexDirection: 'row-reverse'}}
                            disabled={!timeslot}
                            onPress={() => setStep(step + 1)}
                        >
                            Next
                        </Button>
                    </BottomBar>
                </>
            )}
            {appt && step === 2 && (
                <>
                    <PageTitle>Report Additional Needs (Optional)</PageTitle>
                    <PageContent>
                        {additionalNeeds &&
                            additionalNeeds.length &&
                            additionalNeeds.map((additionalNeed, index) => (
                                <View key={index}>
                                    <DropDownSelect
                                        data={classificationSelectItem}
                                        label={'Additional Need Type'}
                                        selection={classificationSelectItem.find(
                                            (item) => item.value === additionalNeed.classification_id
                                        )}
                                        onSelect={(selected) => {
                                            additionalNeeds[index].classification_id = selected;
                                            setAdditionalNeeds([...additionalNeeds]);
                                        }}
                                    />
                                    {hasAdditionalNeed(additionalNeed) && (
                                        <>
                                            <TextInput
                                                mode='outlined'
                                                multiline={true}
                                                placeholder={
                                                    'E.g. “There is a strange vibration coming from the engine that should be checked out.”'
                                                }
                                                value={additionalNeed.memo}
                                                onChangeText={(text) => {
                                                    if (text.length <= 250) {
                                                        additionalNeeds[index].memo = text;
                                                        setAdditionalNeeds([...additionalNeeds]);
                                                    }
                                                }}
                                                style={{height: 150}}
                                            />
                                            <Text style={{color: colors.placeholder}}>
                                                Limit to {additionalNeed.memo.length} / 250 characters
                                            </Text>
                                        </>
                                    )}
                                </View>
                            ))}
                        <Button
                            onPress={() => {
                                setAdditionalNeeds([...additionalNeeds, {classification_id: 0, memo: ''}]);
                            }}
                        >
                            Add Additional Need
                        </Button>
                    </PageContent>
                    <BottomBar style={{justifyContent: 'flex-end'}}>
                        <Button icon='chevron-left' mode='outlined' uppercase={false} onPress={() => setStep(step - 1)}>
                            Back
                        </Button>
                        <View style={{width: 5}} />
                        <Button
                            icon='chevron-right'
                            mode='contained'
                            uppercase={false}
                            contentStyle={{flexDirection: 'row-reverse'}}
                            onPress={() => setStep(step + 1)}
                        >
                            Review
                        </Button>
                    </BottomBar>
                </>
            )}
            {appt && step === 3 && garage && timeslot?.start_time.length && (
                <>
                    <PageTitle>{desc}</PageTitle>
                    <PageContent>
                        <List.Item
                            title={user ? user.first_name + ' ' + user.last_name : 'Missing User Information'}
                            left={(props) => (
                                <MaterialCommunityIcons name='account-outline' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title={appt.vehicle?.description ? appt.vehicle.description : 'Missing Vehicle Description'}
                            description={appt.vehicle?.alias ? appt.vehicle.alias : 'Missing Vehicle Alias'}
                            left={(props) => (
                                <MaterialCommunityIcons name='car-back' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title={
                                DateTime.fromISO(timeslot.start_time).hasSame(
                                    DateTime.fromISO(timeslot.end_time),
                                    'day'
                                )
                                    ? date?.toLocal().toFormat('DDDD')
                                    : DateTime.fromISO(timeslot.start_time).toFormat('DDDD') +
                                      '-' +
                                      DateTime.fromISO(timeslot.end_time).toFormat('DDDD')
                            }
                            description={
                                appt.duration &&
                                DateTime.fromISO(timeslot.start_time).toLocal().toFormat('h:mma') +
                                    '-' +
                                    DateTime.fromISO(timeslot.end_time).toLocal().toFormat('h:mma')
                            }
                            left={(props) => (
                                <MaterialCommunityIcons name='calendar-blank' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title='Expected Duration'
                            description={expDuration}
                            left={(props) => (
                                <MaterialCommunityIcons name='clock-outline' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        {appt.duration && Duration.fromISO(appt.duration) > Duration.fromObject({hours: 8}) && (
                            <WarningTxt>This appointment will take more than 1 day.</WarningTxt>
                        )}
                        <Divider />
                        <List.Item
                            title='Repair Code'
                            description={
                                (appt.repair_code ? appt.repair_code : 'Missing Repair Code') +
                                ': ' +
                                (appt.task_description ? appt.task_description : 'Missing Task Description')
                            }
                            left={(props) => <MaterialCommunityIcons name='tools' size={26} color={colors.primary} />}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title={'Additional Needs'}
                            description={additionalNeeds.map((additionalNeed) => {
                                return hasAdditionalNeed(additionalNeed)
                                    ? classificationSelectItem.find(
                                          (item) => item.value === additionalNeed.classification_id
                                      )?.label +
                                          ': ' +
                                          additionalNeed.memo +
                                          '\n'
                                    : '';
                            })}
                            left={(props) => (
                                <MaterialCommunityIcons name='note-outline' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title={garage.description ? garage.description : 'Missing Garage Description'}
                            titleStyle={{fontWeight: '600'}}
                            left={(props) => (
                                <MaterialIcons name='location-pin' size={26} color={colors.primary} uppercase={false} />
                            )}
                            style={{padding: 0}}
                        />
                        <Card style={{width: '100%', height: 250}}>
                            <MapView
                                initialRegion={{
                                    latitude: getSatelliteBayLatitude(selectedSatelliteBayId, satelliteBays, garage),
                                    longitude: getSatelliteBayLongitude(selectedSatelliteBayId, satelliteBays, garage),
                                    latitudeDelta: 0.0922,
                                    longitudeDelta: 0.0421,
                                }}
                                region={{
                                    latitude: getSatelliteBayLatitude(selectedSatelliteBayId, satelliteBays, garage),
                                    longitude: getSatelliteBayLongitude(selectedSatelliteBayId, satelliteBays, garage),
                                    latitudeDelta: 0.0922,
                                    longitudeDelta: 0.0421,
                                }}
                                style={{width: '100%', height: '70%'}}
                                loadingEnabled={true}
                                loadingIndicatorColor='#666666'
                                loadingBackgroundColor='#eeeeee'
                                showsCompass={false}
                                scrollEnabled={false}
                                toolbarEnabled={false}
                                onPress={() => {
                                    Linking.openURL(
                                        'https://maps.google.com/?q=' + garage.latitude + ',' + garage.longitude
                                    );
                                }}
                            >
                                {/* Somehow the marker title doesn't show*/}
                                {garage && (
                                    <Marker
                                        identifier={'12389dfd'}
                                        key={`test-${garage.id}`}
                                        coordinate={{
                                            latitude: garage.latitude,
                                            longitude: garage.longitude,
                                        }}
                                        title={garage.description}
                                    />
                                )}
                            </MapView>
                            <Card.Content>
                                <Subheading style={{fontWeight: 'bold'}}>{garage.address}</Subheading>
                                <List.Item
                                    title={locationText}
                                    left={(props) => <MaterialIcons name='near-me' size={20} color={colors.primary} />}
                                    style={{padding: 0}}
                                    titleStyle={{color: colors.primary}}
                                />
                            </Card.Content>
                        </Card>
                    </PageContent>
                    <Portal>
                        <Dialog
                            visible={!!dialogError}
                            dismissable={true}
                            onDismiss={() => {
                                setDialogError('');
                                setTimeslot(undefined); // clear time selection since scheduling went wrong
                                setStep(1); // return to timeslot selecting step
                            }}
                        >
                            <Dialog.Title>Scheduling Error</Dialog.Title>
                            <Dialog.Content>
                                <Text>{dialogError}</Text>
                            </Dialog.Content>
                        </Dialog>
                    </Portal>
                    <BottomBar style={{justifyContent: 'flex-end'}}>
                        <Button
                            icon='chevron-left'
                            mode='outlined'
                            uppercase={false}
                            onPress={() => setStep(step - 1)}
                            disabled={loading}
                        >
                            Back
                        </Button>
                        <View style={{width: 5}} />
                        <Button
                            icon='check'
                            mode='contained'
                            disabled={loading}
                            loading={loading}
                            uppercase={false}
                            contentStyle={{flexDirection: 'row-reverse'}}
                            onPress={() => {
                                if (!timeslot || !appt) return;
                                console.log('Attempting to schedule:', timeslot);
                                const api = new APIService();
                                const start_time = DateTime.fromISO(timeslot.start_time);
                                const end_time = DateTime.fromISO(timeslot.end_time);
                                // Try each bay in sequence
                                const tryAsync = async () => {
                                    let work: GarageBayScheduledWork[] | null = null;
                                    const apptWorkOmits: any[] = [];
                                    for (const additionalNeed of additionalNeeds)
                                        if (hasAdditionalNeed(additionalNeed))
                                            apptWorkOmits.push({
                                                uid: user.id,
                                                appt_id: appt.id,
                                                classification_id: additionalNeed.classification_id,
                                                description: additionalNeed.memo,
                                            });

                                    apptWorkOmits.length && (await api.updateApptWork(apptWorkOmits));

                                    const apptIds = [appt.id, ...selectedRelatedAppts.map((rAppt) => rAppt.id)];

                                    try {
                                        work = await api.scheduleAppointment(
                                            timeslot.bay_id,
                                            apptIds,
                                            start_time,
                                            end_time
                                        );
                                    } catch (e: any) {
                                        console.log(e.message);
                                        throw e;
                                    }
                                    if (!work) throw new Error('Unable to schedule appointment');
                                    return work;
                                };
                                setLoading(true);
                                tryAsync()
                                    .then(() => {
                                        setStep(step + 1);
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        setDialogError(err.message);
                                        setLoading(false);
                                    });
                            }}
                        >
                            Confirm
                        </Button>
                    </BottomBar>
                </>
            )}
            {appt && step === 4 && (
                <>
                    <PageContent style={{flex: 1, justifyContent: 'center'}}>
                        <Image
                            source={require('assets/appt-confirmed.png')}
                            style={{width: 207, height: 180, alignSelf: 'center'}}
                        />
                        <Headline style={{textAlign: 'center'}}>
                            {appt.status && appt.status[0].status_code === 'CONFIRMED'
                                ? 'Appointment Rescheduled'
                                : 'Appointment Confirmed'}
                        </Headline>
                        <Text style={{textAlign: 'center'}}>Event invitation will be sent to your email.</Text>
                        <WarningTxt>Vehicles are expected 15 minutes prior to appointment time</WarningTxt>
                        <Button
                            mode='outlined'
                            onPress={() => {
                                // Change the id of appointment to force a update on object
                                // Ok if id invalid here, because id from params is used instead
                                if (appt) appt.id = -1;
                                navigation.navigate('Appointments', {status: 'confirmed'});
                            }}
                            uppercase={false}
                            style={{borderColor: colors.primary}}
                        >
                            View all scheduled
                        </Button>
                        {/*{appt.duration && createICS(appt as any, garage, date, time)}*/}
                    </PageContent>
                </>
            )}
        </View>
    );
}

export async function getDrivingDistance(
    curLocation: {lng: number; lat: number},
    garLocation: {lng: number; lat: number}
): Promise<{distance: number; duration: number} | undefined> {
    const accessToken = 'pk.eyJ1IjoiamluMm1hcGJveCIsImEiOiJjbDUxZmlrYTgwNTQ5M2RwanM0Z3FrMnBuIn0.NuwDHj7fPJTM056o1-SW7w';
    try {
        const response = await fetch(
            `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/${curLocation.lng},${curLocation.lat};${garLocation.lng},${garLocation.lat}?annotations=distance,duration&access_token=${accessToken}`
        );
        const json = await response.json();
        // console.log(json);
        return {distance: json.distances[0][1], duration: json.durations[0][1]};
    } catch (error) {
        console.error(error);
    }
}
function createICS(appt: Appointment & {duration: string}, garage: any, date: DateTime, time: string) {
    let selectStart: DateTime = date;
    selectStart = selectStart.set({hour: DateTime.fromISO(time).hour, minute: DateTime.fromISO(time).minute});
    const event = {
        title: appt.description,
        description:
            "You've scheduled for service of " +
            appt.description +
            ' for vehicle ' +
            appt.vehicle?.description +
            ' at ' +
            garage.description +
            '. Please note vehicles are expected 15 minutes prior to appointment time.',
        startTime: selectStart.toISO(),
        endTime: selectStart.plus(Duration.fromISO(appt.duration)).toISO(),
        location: garage.address,
    };

    return (
        <Button
            mode='contained'
            uppercase={false}
            labelStyle={{color: '#548f96'}}
            theme={{colors: {primary: '#ebebeb'}}}
            style={{shadowOpacity: 0}}
        >
            <ICalendarLink event={event as any} filename={'calendar event.ics'}>
                Add event to my calendar
            </ICalendarLink>
        </Button>
    );
}

const TimeChipContainer = styled.View`
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding: 16px;
    min-height: auto;
`;
