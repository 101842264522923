import React, {useContext, useEffect, useState} from 'react';
import {Linking, ScrollView, View} from 'react-native';
import {StackNavigationProp, StackScreenProps} from '@react-navigation/stack';
import {StackParamList} from 'linking';
import {BottomBar, PageContent, PageTitle, Row, WarningTxt} from 'theme';
import {AuthContext, ApptContext} from 'shared/context';
import {
    Text,
    Button,
    Card,
    Chip,
    Divider,
    List,
    Subheading,
    useTheme,
    Dialog,
    TextInput,
    Portal,
} from 'react-native-paper';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {DateTime, Duration} from 'luxon';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MapView from 'react-native-maps';
import {LocationObject} from 'expo-location/src/Location.types';
import {getDrivingDistance} from '@screens/scheduling';
import * as Location from 'expo-location';
import TopBar from 'components/TopBar';
import {Appointment, VendorWork} from 'shared/appt.model';
import {useNavigation} from '@react-navigation/native';
import {Garage} from 'shared/garage.model';
import DatePicker from 'components/DatePicker.web';
import {APIService} from 'api';

export default function ApptDetails({route}: StackScreenProps<StackParamList, 'ApptDetails'>) {
    const {colors} = useTheme();
    const navigation = useNavigation<StackNavigationProp<StackParamList>>();
    const {Marker} = MapView as any;
    const user = useContext(AuthContext).user;
    const compStatuses = ['COMPLETED', 'VCOMP'];

    const apptContext = useContext(ApptContext);
    const appt = apptContext.value;
    const [garage, setGarage] = useState<Garage | null>(null);
    const [usrLocation, setUsrLocation] = useState<LocationObject | null>(null);
    const [locationText, setLocationText] = useState('Waiting..');

    const [cancelDialogVisible, setCancelDialogVisible] = useState(false);
    const [vdrDialogVisible, setVdrDialogVisible] = useState(false);
    const [vdrDateTime, setVdrDateTime] = useState('');
    const [vdrMemo, setVdrMemo] = useState('');
    let desc = '';

    if (appt?.work_type === 'PM') desc = 'Preventative Maintenance Needed';
    else if (appt?.work_type === 'CMPWO') desc = 'Recall is Required';
    else desc = 'Maintenance Required';

    // Set appointment based on parameter
    useEffect(() => {
        // do nothing if appointment matches parameter
        const id = parseInt(route.params.apptId as any);
        if (appt && appt.id === id) return;

        // fetch appointment data and set context
        const api = new APIService();

        api.getAppointment(id)
            .then((appt) => {
                apptContext.update(appt);
                if (appt.garage_id) return api.getGarageBay(appt.garage_id).then((b) => setGarage(b.garage));
                else setGarage(null);
            })
            .catch((e) => apptContext.update(null));
        // Only need value of appt, not context
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [route.params?.apptId]);

    // Request location permissions on first render
    useEffect(() => {
        (async () => {
            const {status} = await Location.requestForegroundPermissionsAsync();
            if (status !== 'granted') {
                setLocationText('Permission to access location was denied');
                return;
            }

            const location = await Location.getCurrentPositionAsync({});
            setUsrLocation(location);
        })();
    }, []);

    if (garage && usrLocation)
        getDrivingDistance(
            {lng: usrLocation.coords.longitude, lat: usrLocation.coords.latitude},
            {lng: garage.longitude, lat: garage.latitude}
        ).then((val) => {
            if (val)
                setLocationText(
                    (val.distance
                        ? (Math.round((val.distance / 1609.34) * 10) / 10).toString() + ' miles away · '
                        : 'N/A') +
                        (val.duration ? (Math.round((val.duration / 60) * 10) / 10).toString() + ' min drive' : '')
                );
        });

    const isApptTooLong = (appt: Appointment) => {
        return appt.duration && Duration.fromISO(appt.duration) > Duration.fromObject({hours: 16});
    };

    const apptWarning = (appt: Appointment) => {
        if (
            appt.scheduled_starttime !== null &&
            DateTime.fromISO(appt.scheduled_starttime) < DateTime.now().plus({day: 2})
        )
            return (
                <WarningTxt>
                    Appointments cannot be rescheduled or cancelled within 48 hours of the scheduled time. Please reach
                    out to your garage directly.
                </WarningTxt>
            );

        if (isApptTooLong(appt))
            return (
                <WarningTxt>
                    This appointment is too long to be scheduled through Self-Service. Please reach out to your garage
                    directly.
                </WarningTxt>
            );

        if (appt.duration && Duration.fromISO(appt.duration) > Duration.fromObject({hours: 8}))
            return <WarningTxt>This appointment will take more than 1 day.</WarningTxt>;
    };

    return (
        <View style={{flex: 1, backgroundColor: 'white'}}>
            <TopBar />
            {appt ? (
                <>
                    <Row alignment='center' style={{paddingRight: 16}}>
                        <PageTitle>
                            {(appt.wonum ? appt.wonum : 'N/A') +
                                ' - ' +
                                (desc ? desc : 'Missing Appointment Description')}
                        </PageTitle>
                        {appt.vendor_performed && (
                            <Chip style={{backgroundColor: '#3F569F'}} textStyle={{color: 'white'}}>
                                Vendor
                            </Chip>
                        )}
                    </Row>
                    <PageContent>
                        {appt.operator_id && appt.operator_id !== user.id && (
                            <List.Item
                                title={user ? user.first_name + ' ' + user.last_name : 'Vehicle has no Operator'}
                                left={(props) => (
                                    <MaterialCommunityIcons name='account-outline' size={26} color={colors.primary} />
                                )}
                                style={{padding: 0}}
                            />
                        )}
                        <Divider />
                        <List.Item
                            title={appt.vehicle?.description ? appt.vehicle.description : 'Missing Vehicle Description'}
                            description={appt.vehicle?.alias ? appt.vehicle.alias : 'Missing Vehicle Alias'}
                            left={(props) => (
                                <MaterialCommunityIcons name='car-back' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        {!appt.vendor_performed && appt.scheduled_starttime && appt.scheduled_endtime && appt.duration && (
                            <>
                                <List.Item
                                    title={
                                        DateTime.fromISO(appt.scheduled_starttime).hasSame(
                                            DateTime.fromISO(appt.scheduled_endtime),
                                            'day'
                                        )
                                            ? DateTime.fromISO(appt.scheduled_starttime).toFormat('DDDD')
                                            : DateTime.fromISO(appt.scheduled_starttime).toFormat('DDDD') +
                                              '-' +
                                              DateTime.fromISO(appt.scheduled_endtime).toFormat('DDDD')
                                    }
                                    description={
                                        DateTime.fromISO(appt.scheduled_starttime).toFormat('h:mma') +
                                        '-' +
                                        DateTime.fromISO(appt.scheduled_endtime).toFormat('h:mma')
                                    }
                                    left={(props) => (
                                        <MaterialCommunityIcons
                                            name='calendar-blank'
                                            size={26}
                                            color={colors.primary}
                                        />
                                    )}
                                    style={{padding: 0}}
                                />
                                <Divider />
                            </>
                        )}
                        <List.Item
                            title='Expected Duration'
                            description={appt.duration ? Duration.fromISO(appt.duration).toHuman() : 'Missing Duration'}
                            left={(props) => (
                                <MaterialCommunityIcons name='clock-outline' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        {apptWarning(appt)}
                        <Divider />
                        <List.Item
                            title='Repair Code'
                            description={
                                (appt.repair_code ? appt.repair_code : 'Missing Repair Code') +
                                ': ' +
                                (appt.task_description ? appt.task_description : 'Missing Task Description')
                            }
                            left={(props) => <MaterialCommunityIcons name='tools' size={26} color={colors.primary} />}
                            style={{padding: 0}}
                        />
                        <Divider />
                        <List.Item
                            title={'Additional Needs'}
                            description={
                                appt.related_work
                                    ? appt.related_work.map((apptWork) => {
                                          return apptWork?.description + '\n';
                                      })
                                    : 'N/A'
                            }
                            left={(props) => (
                                <MaterialCommunityIcons name='note-outline' size={26} color={colors.primary} />
                            )}
                            style={{padding: 0}}
                        />
                        <Divider />
                        {appt.vendor_performed ? (
                            <>
                                <List.Item
                                    title={() => (
                                        <>
                                            <Text>Go to a local vendor for service and use Work Order number</Text>
                                            <Subheading style={{color: colors.accent}}>{appt.wonum}</Subheading>
                                        </>
                                    )}
                                    titleStyle={{fontWeight: '600'}}
                                    left={(props) => (
                                        <MaterialIcons
                                            name='location-pin'
                                            size={26}
                                            color={colors.primary}
                                            uppercase={false}
                                        />
                                    )}
                                    style={{padding: 0}}
                                />
                            </>
                        ) : (
                            garage && (
                                <>
                                    <List.Item
                                        title={garage.description ? garage.description : 'Garage Missing Description'}
                                        titleStyle={{fontWeight: '600'}}
                                        left={(props) => (
                                            <MaterialIcons
                                                name='location-pin'
                                                size={26}
                                                color={colors.primary}
                                                uppercase={false}
                                            />
                                        )}
                                        style={{padding: 0}}
                                    />
                                    <Card style={{width: '100%', height: 250}}>
                                        <MapView
                                            initialRegion={{
                                                latitude: garage.latitude,
                                                longitude: garage.longitude,
                                                latitudeDelta: 0.0922,
                                                longitudeDelta: 0.0421,
                                            }}
                                            region={{
                                                latitude: garage.latitude,
                                                longitude: garage.longitude,
                                                latitudeDelta: 0.0922,
                                                longitudeDelta: 0.0421,
                                            }}
                                            style={{width: '100%', height: '70%'}}
                                            loadingEnabled={true}
                                            loadingIndicatorColor='#666666'
                                            loadingBackgroundColor='#eeeeee'
                                            showsCompass={false}
                                            scrollEnabled={false}
                                            toolbarEnabled={false}
                                            onPress={() => {
                                                Linking.openURL(
                                                    'https://maps.google.com/?q=' +
                                                        garage.latitude +
                                                        ',' +
                                                        garage.longitude
                                                );
                                            }}
                                        >
                                            {/* Somehow the marker title doesn't show*/}
                                            {garage && (
                                                <Marker
                                                    identifier={'12389dfd'}
                                                    key={`test-${garage.id}`}
                                                    coordinate={{
                                                        latitude: garage.latitude,
                                                        longitude: garage.longitude,
                                                    }}
                                                    title={garage.description}
                                                />
                                            )}
                                        </MapView>
                                        <Card.Content>
                                            <Subheading style={{fontWeight: 'bold'}}>{garage.address}</Subheading>
                                            <List.Item
                                                title={locationText}
                                                left={(props) => (
                                                    <MaterialIcons name='near-me' size={20} color={colors.primary} />
                                                )}
                                                style={{padding: 0}}
                                                titleStyle={{color: colors.primary}}
                                            />
                                        </Card.Content>
                                    </Card>
                                </>
                            )
                        )}
                    </PageContent>
                    {appt.status && !compStatuses.includes(appt.status[0].status_code) && (
                        <div>
                            {appt.vendor_performed ? (
                                <BottomBar>
                                    <Button
                                        mode='contained'
                                        uppercase={false}
                                        style={{width: '100%'}}
                                        onPress={() => {
                                            const date = new Date();
                                            const year = date.getFullYear().toString();
                                            const month = ('0' + (date.getMonth() + 1).toString()).slice(-2);
                                            const day = ('0' + date.getDate().toString()).slice(-2);
                                            const hours = ('0' + date.getHours().toString()).slice(-2);
                                            const minutes = ('0' + date.getMinutes().toString()).slice(-2);
                                            setVdrDateTime(
                                                year + '-' + month + '-' + day + 'T' + hours + ':' + minutes
                                            );
                                            setVdrDialogVisible(true);
                                        }}
                                    >
                                        Report Completed
                                    </Button>
                                </BottomBar>
                            ) : (
                                <BottomBar>
                                    <Button
                                        disabled={
                                            appt.scheduled_starttime !== null &&
                                            DateTime.fromISO(appt.scheduled_starttime) < DateTime.now().plus({day: 2})
                                        }
                                        mode='outlined'
                                        onPress={() => setCancelDialogVisible(true)}
                                        uppercase={false}
                                        style={{margin: 'auto', width: '49%', borderColor: colors.accent}}
                                        theme={{colors: {primary: colors.accent}}}
                                    >
                                        Cancel
                                    </Button>
                                    {!isApptTooLong(appt) && (
                                        <Button
                                            disabled={
                                                appt.scheduled_starttime !== null &&
                                                DateTime.fromISO(appt.scheduled_starttime) <
                                                    DateTime.now().plus({day: 2})
                                            }
                                            mode='outlined'
                                            uppercase={false}
                                            style={{margin: 'auto', width: '49%'}}
                                            onPress={() => {
                                                // Use `push` instead of `navigate` to clear any old scheduling params
                                                navigation.push('Scheduling', {
                                                    apptId: appt.id,
                                                });
                                            }}
                                        >
                                            Reschedule
                                        </Button>
                                    )}
                                </BottomBar>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Text style={{fontWeight: '600'}}>Appointment not found or not authorized.</Text>
                </>
            )}
            <Portal>
                <Dialog
                    style={{marginLeft: 'auto', marginRight: 'auto', width: '50%'}}
                    visible={vdrDialogVisible}
                    onDismiss={() => setVdrDialogVisible(false)}
                >
                    <Dialog.Title>Report Completed</Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{paddingVertical: 16}}>
                            <Subheading style={{fontWeight: 'bold'}}>Service Date & Time</Subheading>
                            <DatePicker
                                mode={'datetime-local'}
                                style={{
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    padding: 8,
                                    borderColor: '#0000008a',
                                    borderRadius: 8,
                                    marginBottom: 16,
                                }}
                                value={vdrDateTime}
                                onChange={(e) => {
                                    setVdrDateTime(e.currentTarget.value);
                                    // console.log(e.currentTarget.value);
                                    // console.log(e.currentTarget.value, DateTime.fromISO(e.currentTarget.value).toLocaleString());
                                }}
                            />
                            <Divider />
                            <Subheading style={{fontWeight: 'bold', marginTop: 16}}>Vendor Detail</Subheading>
                            <TextInput
                                mode='outlined'
                                multiline={true}
                                label='Description of Vendor Work Performed'
                                value={vdrMemo}
                                onChangeText={(text) => setVdrMemo(text)}
                                style={{height: 150}}
                            />
                        </ScrollView>
                    </Dialog.ScrollArea>
                    <Dialog.Actions>
                        <Button
                            onPress={() => {
                                setVdrDialogVisible(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                const api = new APIService();
                                const vendorWork = {
                                    appt_id: appt ? appt.id : route.params.apptId,
                                    memo: vdrMemo,
                                    update_time: vdrDateTime,
                                    updated_by: user.id.toString(),
                                };
                                api.updateVendorWork(vendorWork as any)
                                    .then(() => {
                                        // Change the id of appointment to force a update on object
                                        // Ok if id invalid here, because id from params is used instead
                                        if (appt) appt.id = -1;
                                        setVdrMemo('');
                                        setVdrDialogVisible(false);
                                    })
                                    .catch((err) => {
                                        setVdrMemo('');
                                        console.log(err);
                                    });
                            }}
                        >
                            Submit
                        </Button>
                    </Dialog.Actions>
                </Dialog>
                <Dialog visible={cancelDialogVisible} onDismiss={() => setCancelDialogVisible(false)}>
                    <Dialog.Title>Confirm Cancel</Dialog.Title>
                    <Dialog.ScrollArea>
                        <ScrollView contentContainerStyle={{paddingVertical: 16}}>
                            <Subheading style={{fontWeight: 'bold', marginTop: 16}}>
                                Confirm cancellation of this appointment?
                            </Subheading>
                        </ScrollView>
                    </Dialog.ScrollArea>
                    <Dialog.Actions>
                        <Button
                            onPress={() => {
                                setCancelDialogVisible(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={() => {
                                // fetch appointment data and set context
                                const api = new APIService();
                                if (appt) {
                                    api.cancelAppointment(appt.id)
                                        .then(() => {
                                            setCancelDialogVisible(false);
                                            navigation.navigate('Appointments', {
                                                status: 'unscheduled',
                                                tab: undefined,
                                            });
                                        })
                                        .catch((err) => {
                                            console.log(err);
                                        });
                                } else {
                                    console.log('Appointment does not exist');
                                    setCancelDialogVisible(false);
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
}
